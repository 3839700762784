import { graphql } from 'gatsby';
import * as React from 'react';
import { GlobalContext } from '../context/GlobalContext';
import Header from '../components/header/Header';
import Seo from '../components/Seo';
import Footer from '../components/footer/Footer';
import RegistryDetails from '../components/registry-details/RegistryDetails';
import resolveInternalLinks from '../helper/resolveInternalLinks';

export default function Registry({
  data: {
    umbraco: { varaRegistry },
  },
  pageContext: { langCode, settings, slug },
}) {
  resolveInternalLinks(settings);
  resolveInternalLinks(varaRegistry);

  const translations = settings?.translations ? settings?.translations?.map((t) => t.content) : [];
  const dir = langCode === 'ar' ? 'rtl' : 'ltr';

  const values = {
    langCode,
    translations,
    settings,
    slug,
    dir,
  };

  const memo = React.useMemo(() => values, [values]);
  return (
    <GlobalContext.Provider value={memo}>
      <Seo lang={langCode} page={varaRegistry} dir={dir} />
      <Header
        data={settings}
        dir={dir}
        lang={langCode}
        currentPage={{
          id: varaRegistry.id,
          url: varaRegistry.url,
          udi: `umb://document/${varaRegistry.id.replace(/-/g, '')}`,
        }}
      />
      <RegistryDetails data={varaRegistry} dir={dir} />
      <Footer data={settings} dir={dir} lang={langCode} currentPage={varaRegistry.id} />
    </GlobalContext.Provider>
  );
}

export const query = graphql`
  query RegistryQuery($id: ID!, $lang: String!) {
    umbraco {
      varaRegistry(id: $id, culture: $lang) {
        id
        name
        url
        title
        subtitle
        description
        featuredImage {
          url
        }
        cssClass
        metadataTitle
        metadataDescription
        metadataKeywords
        socialMediaImage {
          url
        }
        bannerText
        bannerSubtitle
        registrySubtitle
        registryTitle
        vASPName
        vASPReference
        registryAddress
        registryWebsite
        alertTitle
        alertDescription
        licenseTitle
        vARALicence
        vARAStatus
        licenseType
        licenseDescription
        varaReferenceNumber
        licenseIssuanceDate
        licenseActivities
      }
    }
  }
`;
