import React from 'react';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import Icons from '../layouts/Icons';
import { dateToNum } from '../../helper/dateToString';

export default function RegistryLicenceCard({
  title,
  subtitle,
  licence,
  status,
  description,
  licenceNumber,
  date,
  activities,
  name,
}) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.4, delay: 0.2 }}
      className="bg-white">
      <button
        type="button"
        className="relative flex h-20 w-full cursor-auto items-center justify-between
            px-5 leading-8 before:absolute before:bottom-0 before:left-0 before:z-0
            before:!inline before:h-[1px] before:w-full before:bg-bg-4 before:content-[''] 
            lg:h-24 lg:px-10">
        <span
          className="text-gradient py-3 text-left text-lg font-light ltr:font-space-grotesk 
          rtl:font-almarai
          md:py-0 ltr:lg:ltr:tracking-[2px] xl:text-[22px] 3xl:text-xl">
          {title}
        </span>
      </button>

      <div>
        <div
          className="flex flex-col gap-y-5 px-5 pb-10 pt-5 lg:gap-y-10 lg:px-10
            lg:pb-20 lg:pt-7">
          {/* <h5
              className="text-primary rtl:font-almarai ltr:font-space-medium 3xl:text-base lg:leading-9
              xl:text-[16px] text-[15px] leading-7">
              {subtitle}
            </h5> */}
          <div
            className="flex flex-col gap-y-6 md:gap-x-[50px] lg:flex-row lg:gap-y-0
              xl:gap-x-[70px] 2xl:gap-x-[141px]">
            <div className="flex flex-col gap-y-5 lg:w-[50%]  lg:gap-y-10 2xl:w-[45%]">
              {isEmpty(licence) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="text-[15px] font-light leading-7 text-dim-gray 
                    ltr:font-space-grotesk rtl:font-almarai xl:text-[16px]
                                2xl:text-base 2xl:leading-9">
                    VARA Licence:
                  </span>
                  <h4
                    className="text-[16px] font-light leading-7 text-primary ltr:font-space-grotesk
                                rtl:font-almarai xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]">
                    {licence}
                  </h4>
                </div>
              )}

              {isEmpty(activities) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="text-[15px] font-light leading-7 text-dim-gray ltr:font-space-grotesk rtl:font-almarai xl:text-[16px]
                              2xl:text-base 2xl:leading-9">
                    Licence activities:
                  </span>
                  <div>
                    {isEmpty(activities) ||
                      activities.map((item, index) => (
                        <p
                          target="_blank"
                          key={index}
                          className="rtl:font-almarai ltr:font-space-grotesk text-[16px] font-light leading-7
                                    text-primary xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]">
                            {item}
                        </p>
                      ))}
                  </div>
                </div>
              )}
              {isEmpty(date) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="text-[15px] font-light leading-7 text-dim-gray ltr:font-space-grotesk rtl:font-almarai xl:text-[16px]
                                2xl:text-base 2xl:leading-9">
                    Licence issued on:
                  </span>
                  <h4
                    className="text-[16px] font-light leading-7 text-primary ltr:font-space-grotesk
                                rtl:font-almarai xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]">
                    {dateToNum(date)}
                  </h4>
                </div>
              )}

              {/* <div className="flex flex-col gap-y-3">
                  <span
                    className="text-dim-gray 2xl:text-base 2xl:leading-9 xl:text-[16px] text-[15px] leading-7
                    rtl:font-almarai ltr:font-space-grotesk font-light">
                    VARA reference number:
                  </span>
                  <h4
                    className="text-primary 2xl:text-xl xl:text-[20px] text-[16px]
                    2xl:leading-[52px] xl:leading-10 leading-7 rtl:font-almarai ltr:font-space-grotesk font-light">
                    {licenceNumber}
                  </h4>
                </div> */}
            </div>
            <div className="flex flex-col gap-y-5 lg:w-[50%]  lg:gap-y-10 2xl:w-[45%]">
              {isEmpty(description) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="text-[15px] font-light leading-7 text-dim-gray ltr:font-space-grotesk rtl:font-almarai xl:text-[16px]
                  2xl:text-base 2xl:leading-9">
                    Licence Condition:
                  </span>
                  <div
                    className="2xl:leading:8 flex flex-col gap-y-3 text-[16px] font-light
                leading-7 text-primary html-content-h4:text-[16px] html-content-h4:leading-10
                ltr:font-space-grotesk
                rtl:font-almarai html-content-h4:xl:text-[20px]
                2xl:text-base html-content-h4:2xl:text-xl html-content-h4:2xl:leading-[52px]"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              )}
              {isEmpty(status) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="text-[15px] font-light leading-7 text-dim-gray ltr:font-space-grotesk rtl:font-almarai xl:text-[16px]
                                2xl:text-base 2xl:leading-9">
                    Licence Status:
                  </span>
                  <h4
                    className="text-[16px] font-light leading-7 text-primary ltr:font-space-grotesk
                                rtl:font-almarai xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]">
                    {status}
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-gradient-line h-[2px] w-full" />
      </div>
    </motion.div>
  );
}

