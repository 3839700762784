import React from 'react';
import { isEmpty } from 'lodash';
import BoxedContainer from '../layouts/BoxedContainer';
import RegistryDetailsBanner from './RegistryDetailsBanner';
import AlertBox from '../layouts/AlertBox';
import RegistryCommercialCard from './RegistryCommercialCard';
import RegistryLicenceCard from './RegistryLicenceCard';
import RegistryRegulatoryNotice from './RegistryRegulatoryNotice';
import Notice from '../../services/Notice';

export default function RegistryDetails({ data, dir }) {
  console.log(data);
  const {
    bannerSubtitle,
    title,
    varaReferenceNumber,
    bannerText,
    alertTitle,
    alertDescription,
    registryTitle,
    vASPName,
    vASPReference,
    registrySubtitle,
    registryAddress,
    registryWebsite,
    licenseTitle,
    vARALicence,
    vARAStatus,
    licenseType,
    licenseDescription,
    licenseActivities,
    licenseIssuanceDate,
  } = data;
  const isArabic = dir === 'rtl';
  const notice = new Notice();
  const allNotice = notice.get(isArabic ? 'ar' : 'en');
  const matchingNotice = allNotice.find((notice) => {
    if (notice.noticeRegistry !== null) {
      return notice.noticeRegistry.name === title;
    }
  });
  return (
    <section className="bg-bg-2 pb-20 pt-[130px] lg:pb-[140px] lg:pt-[200px] xl:pb-[180px]">
      <BoxedContainer>
        <div className="flex flex-col gap-y-6 lg:gap-y-10">
          <RegistryDetailsBanner
            title={title}
            subtitle={bannerSubtitle}
            licenceNumber={varaReferenceNumber}
            text={bannerText}
          />
          <AlertBox title={alertTitle} description={alertDescription} />
          <RegistryCommercialCard
            title={registryTitle}
            name={vASPName}
            reflection={vASPReference}
            subtitle={registrySubtitle}
            address={registryAddress}
            website={registryWebsite}
          />
          <RegistryLicenceCard
            name={vASPName}
            title={licenseTitle}
            subtitle={licenseType}
            licence={vARALicence}
            status={vARAStatus}
            description={licenseDescription}
            licenceNumber={varaReferenceNumber}
            activities={licenseActivities}
            date={licenseIssuanceDate}
          />
          {isEmpty(matchingNotice) || <RegistryRegulatoryNotice title={matchingNotice.name} />}
        </div>
      </BoxedContainer>
    </section>
  );
}
