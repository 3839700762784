import React from 'react';
import { isEmpty } from 'lodash';
import { motion } from 'framer-motion';

export default function RegistryDetailsBanner({ title, text, subtitle, licenceNumber }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.3 }}
      viewport={{ once: true }}
      className="flex w-full flex-col gap-y-5 md:w-[81%] lg:w-[73%] lg:gap-y-[38px] xl:w-[715px] 3xl:w-[821px]"
    >
      <div className="flex flex-col gap-y-4 lg:gap-y-9">
        {isEmpty(subtitle) || (
          <span
            className="rtl:font-almarai ltr:font-space-grotesk text-[13px] font-light uppercase leading-7
          text-primary ltr:tracking-[3px] lg:text-[14px] ltr:xl:tracking-[5px] 3xl:text-lg
          ltr:3xl:tracking-[8px]"
          >
            {subtitle}
          </span>
        )}
        {isEmpty(title) || (
          <h1
            className="text-gradient rtl:font-almarai ltr:font-space-grotesk text-[26px] font-light
          leading-[42px] lg:w-[75%] lg:text-[35px] lg:leading-[55px] xl:text-[46px]
          xl:leading-[70px] 3xl:text-[56px] 3xl:leading-[88px]"
          dangerouslySetInnerHTML={{ __html: title}} />
        )}
      </div>
      {isEmpty(text) || (
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className="flex w-full flex-col
          gap-y-6 text-black html-content-inner:rtl:font-almarai html-content-inner:ltr:font-space-grotesk
          html-content-inner:text-[15px] html-content-inner:font-light html-content-inner-link:font-space-medium 
          html-content-inner-link:text-[15px]
          html-content-inner-link:leading-7 html-content-inner:lg:text-[16px]
          html-content-inner:lg:leading-9 html-content-inner-link:lg:text-[16px]
          html-content-inner:3xl:text-base html-content-inner-link:3xl:text-[18px]"
        />
      )}
    </motion.div>
  );
}
