import React from 'react';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import Icons from '../layouts/Icons';

export default function RegistryCommercialCard({
  title,
  name,
  reflection,
  subtitle,
  address,
  website,
}) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.4, delay: 0.2 }}
      className="bg-white"
    >
      <button
        type="button"
        className="relative flex h-20 w-full cursor-auto items-center justify-between
        px-5 before:absolute before:bottom-0 before:left-0 before:z-0 before:!inline
        before:h-[1px] before:w-full before:bg-bg-4 before:content-[''] lg:h-24 lg:px-10"
      >
        <span
          className="text-gradient py-3 ltr:text-left rtl:text-right rtl:font-almarai ltr:font-space-grotesk text-lg font-light
        md:py-0 ltr:lg:ltr:tracking-[2px] xl:text-[22px] 3xl:text-xl"
        >
          {title}
        </span>
      </button>
      <div>
        <div
          className="flex flex-col gap-y-5 px-5 pb-10 pt-5 lg:gap-y-10 lg:px-10
            lg:pb-20 lg:pt-7"
        >
          {/* <h5
              className="text-primary rtl:font-almarai ltr:font-space-medium 3xl:text-base lg:leading-9
            xl:text-[16px] text-[15px] leading-7">
              {subtitle}
            </h5> */}
            
          <div
            className="flex flex-col gap-y-6 lg:flex-row lg:gap-x-[70px] lg:gap-y-0 xl:gap-x-[140px]
            2xl:gap-x-[266px]"
          >
            <div className="flex flex-col gap-y-5 lg:w-[45%] 2xl:w-[40%] lg:gap-y-10">
              {isEmpty(name) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="rtl:font-almarai ltr:font-space-grotesk text-[15px] font-light leading-7 text-dim-gray lg:text-[16px]
              3xl:text-base 3xl:leading-9"
                  >
                    VASP Name:
                  </span>
                  <div
                    className="rtl:font-almarai ltr:font-space-grotesk text-[16px] font-light leading-7
                    text-primary xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]"
                    dangerouslySetInnerHTML={{ __html: name }}
                  />
                </div>
              )}

              {isEmpty(address) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="rtl:font-almarai ltr:font-space-grotesk text-[15px] font-light leading-7 text-dim-gray lg:text-[16px]
                            3xl:text-base 3xl:leading-9"
                  >
                    Address (principle place of business):
                  </span>
                  <div
                    className="rtl:font-almarai ltr:font-space-grotesk text-[16px] font-light leading-7
                    text-primary xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]"
                    dangerouslySetInnerHTML={{ __html: address }}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-y-5 lg:gap-y-10">
              {isEmpty(reflection) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="rtl:font-almarai ltr:font-space-grotesk text-[15px] font-light leading-7 text-dim-gray lg:text-[16px]
                              3xl:text-base 3xl:leading-9"
                  >
                    VASP Reference:
                  </span>
                  <p
                    className="rtl:font-almarai ltr:font-space-grotesk text-[16px] font-light leading-7
                    text-primary xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]"
                  >
                    {reflection}
                  </p>
                </div>
              )}

              {isEmpty(website) || (
                <div className="flex flex-col gap-y-3">
                  <span
                    className="rtl:font-almarai ltr:font-space-grotesk text-[15px] font-light leading-7 text-dim-gray lg:text-[16px]
                                3xl:text-base 3xl:leading-9"
                  >
                    Website URL:
                  </span>
                  <a
                    href={website}
                    target="_blank"
                    className="rtl:font-almarai ltr:font-space-grotesk text-[16px] font-light leading-7
                    text-primary xl:text-[20px] xl:leading-10 2xl:text-xl 2xl:leading-[52px]"
                    rel="noreferrer"
                  >
                    {website}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-gradient-line h-[2px] w-full" />
      </div>
    </motion.div>
  );
}
