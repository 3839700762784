import { graphql, useStaticQuery } from 'gatsby';
import resolveInternalLinks from '../helper/resolveInternalLinks';

export default class Notice {
  constructor(_data = {}, _notice = []) {
    this.notice = _notice;
    this.data = _data;
  }
  query(type = 'en') {
    this.data = useStaticQuery(graphql`
      query {
        umbraco {
          allVaraNoticeAr: allVaraNotice(orderBy: noticeDate_DESC, culture: "ar") {
            items {
              contentTypeAlias
              createDate
              cssClass
              description
              name
              noticeBody
              noticeCategory
              noticeDate
              url
              parent {
                url
              }
              subtitle
              title
              noticeIsActive
              noticeRegistry {
                name
                url
              }
            }
          }
          allVaraNotice(orderBy: noticeDate_DESC) {
            items {
              contentTypeAlias
              createDate
              cssClass
              description
              name
              noticeBody
              noticeCategory
              noticeDate
              url
              parent {
                url
              }
              subtitle
              title
              noticeIsActive
              noticeRegistry {
                name
                url
              }
            }
          }
        }
      }
    `);

    if (type === 'ar') {
      this.notice = this.data.umbraco.allVaraNoticeAr.items;
    } else {
      this.notice = this.data.umbraco.allVaraNotice.items;
    }
  }

  get(type) {
    this.query(type);
    resolveInternalLinks(this.notice);
    return this.notice;
  }
}
