import React from 'react';
import { motion } from 'framer-motion';
import Icons from '../layouts/Icons';

export default function RegistryRegulatoryNotice({ title }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.4, delay: 0.2 }}
      className="bg-white"
    >
      <button
        type="button"
        className="relative flex h-20 w-full cursor-auto items-center justify-between
            px-5 py-3 before:absolute before:bottom-0 before:left-0 before:z-0
            before:!inline before:h-[1px] before:w-full before:bg-bg-4 before:content-[''] md:py-0 lg:h-24 lg:px-10"
      >
        <span
          className="text-gradient py-3 ltr:text-left rtl:text-right rtl:font-almarai ltr:font-space-grotesk text-lg font-light
          md:py-0 ltr:lg:ltr:tracking-[2px] xl:text-[22px] 3xl:text-xl"
        >
          Previous regulatory notices
        </span>
        <span className="w-[30px]">{clicked ? <Icons icon="minus" /> : <Icons icon="plus" />}</span>
      </button>
      <div>
        <div
          className="flex flex-col gap-y-5 px-5 pb-10 pt-5 lg:gap-y-10 lg:px-10
          lg:pb-20 lg:pt-7"
        >
          <p
            className="rtl:font-almarai ltr:font-space-grotesk text-[15px] font-light leading-7 text-primary
            xl:text-base xl:leading-9"
          >
            {title}
          </p>
        </div>
        <div className="bg-gradient-line h-[2px] w-full" />
      </div>
    </motion.div>
  );
}
