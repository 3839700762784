import React from 'react';
import { isEmpty } from 'lodash';
import { motion } from 'framer-motion';
import Icons from './Icons';

export default function AlertBox({ title, description }) {
  return (
    isEmpty(title) || (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: 0.4 }}
        viewport={{ once: true }}
        className="flex gap-x-4 border-l-4 border-charcoal-blue bg-light-gray-blue pb-5 pl-5 pr-5 pt-5  md:gap-x-9 md:pb-12
    md:pl-12 md:pr-[60px] md:pt-9"
      >
        <div>
          <Icons icon="alert" />
        </div>
        <div className="flex flex-col gap-y-4">
          {isEmpty(title) || (
            <h5 className="rtl:font-almarai ltr:font-space-medium text-[15px] leading-7 text-primary xl:text-base xl:leading-9">
              {title}
            </h5>
          )}
          {isEmpty(description) || (
            <div
              className="html-content-inner:rtl:font-almarai ltr:font-space-grotesk html-content-inner:text-[15px]
          html-content-inner:font-light html-content-inner:leading-7
          html-content-inner:text-primary html-content-inner:xl:leading-8
          html-content-inner:3xl:text-base"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      </motion.div>
    )
  );
}
